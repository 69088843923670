footer{    
    position: absolute;
    color: #FFF;
    width: 100%;
    text-align: center;
    line-height: 100px;
    position: absolute;
    bottom: 0.5rem;
    height: 5rem;
    overflow:hidden;
    padding-bottom: 1rem;
}
.footer-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-align: center;
    position: relative;
}
.socials{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.socials li{
    margin-top: 20px;
    margin-right: 15px;
}
.socials a{
    text-decoration: none;
    color: #fff;
    border: 1px solid white;
    padding: 10px;
    border-radius: 50%;
}
.socials a i{
    font-size: 1.5rem;
    width: 20px;
    transition: color .4s ease;
}
.socials a:hover i{
    color: aqua;
}

#footer-rigth {
    margin-left: auto; 
    margin-right: 5rem;
    margin-top: 20px;
}

select {
    color: white;
    font-size: 1rem;
}